import { axios } from '@/utils/request'
const publicHeader = 'public'
// 字典配置
export function findDictItemByCode(dictCodes) {
  return axios({
    url: `dict/findDictItemByCode/${dictCodes}/anon`,
    method: 'get',
    publicHeader,
  })
}
export function getMerchantPage(parameter = {}) {
  return axios({
    url: '/merchant/getMerchantPage',
    method: 'post',
    data: parameter,
  })
}

export function saveMerchant(parameter = {}) {
  return axios({
    url: '/merchant/saveMerchant',
    method: 'post',
    data: parameter,
  })
}

export function updateMerchant(parameter = {}) {
  return axios({
    url: '/merchant/updateMerchant',
    method: 'post',
    data: parameter,
  })
}
export function getOrderPage(parameter = {}) {
  return axios({
    url: '/platformOrder/getOrderPage',
    method: 'post',
    data: parameter,
  })
}
export function getOrderById(parameter = {}) {
  return axios({
    url: '/platformOrder/getOrderById',
    method: 'post',
    data: parameter,
  })
}
// merchant/getMerchantById
export function getMerchantById(parameter = {}) {
  return axios({
    url: 'merchant/getMerchantById',
    method: 'post',
    data: parameter,
  })
}
// /merchant/generateServerPublicKey
export function generateServerPublicKey(parameter = {}) {
  return axios({
    url: 'merchant/generateServerPublicKey',
    method: 'post',
    data: parameter,
  })
}
// /merchant/saveSecretKey
export function saveSecretKey(parameter = {}) {
  return axios({
    url: 'merchant/saveSecretKey',
    method: 'post',
    data: parameter,
  })
}

export function getBankOrderPage(parameter = {}) {
  return axios({
    url: '/bankOrder/getOrderPage',
    method: 'post',
    data: parameter,
  })
}

export function exportOrderList(parameter = {}) {
  return axios({
    url: '/bankOrder/exportOrderList',
    method: 'post',
    data: parameter,
    maxBodyLength: Infinity,
    responseType: 'blob',
  })
}
export function getOrderBillDetailPage(parameter = {}) {
  return axios({
    url: '/orderBillDetail/getOrderBillDetailPage',
    method: 'post',
    data: parameter,
  })
}
export function exportOrderBillDetailList(parameter = {}) {
  return axios({
    url: '/orderBillDetail/exportOrderBillDetailList',
    method: 'post',
    data: parameter,
    maxBodyLength: Infinity,
    responseType: 'blob',
  })
}

export function exportPlatformOrderList(parameter = {}) {
  return axios({
    url: '/platformOrder/exportOrderList',
    method: 'post',
    data: parameter,
    maxBodyLength: Infinity,
    responseType: 'blob',
  })
}

// /settle/getSettlePage
export function getSettlePage(parameter = {}) {
  return axios({
    url: '/settle/getSettlePage',
    method: 'post',
    data: parameter,
  })
}

// /settle/downloadSettleTemplate
export function downloadSettleTemplate(parameter = {}) {
  return axios({
    url: '/settle/downloadSettleTemplate',
    method: 'post',
    data: parameter,
    maxBodyLength: Infinity,
    responseType: 'blob',
  })
}

// 3、上传结算文件	post 	/settle/uploadSettleExcel
// 入参：MultipartFile file
export function uploadSettleExcel(parameter = {}) {
  return axios({
    url: '/settle/uploadSettleExcel',
    method: 'post',
    data: parameter,
    type: 'form',
  })
}

export function activeSettle(parameter = {}) {
  return axios({
    url: '/settle/activeSettle',
    method: 'post',
    data: parameter,
  })
}

// /platformOrder/notifyPayInOrders      通知（商户代收订单查询页面）
// /platformOrder/notifyPayOutOrders   通知（商户代付订单查询页面）
export function notifyPayInOrders(data = {}) {
  return axios({
    url: '/platformOrder/notifyPayInOrders',
    method: 'post',
    maxBodyLength: Infinity,
    data,
  }) 
}

export function notifyPayOutOrders(data = {}) {
  return axios({
    url: '/platformOrder/notifyPayOutOrders',
    method: 'post',
    maxBodyLength: Infinity,
    data,
  }) 
}

export function downloadMerchantConfig(parameter) {
  return axios({
    url: '/merchant/downloadMerchantConfig',
    method: 'post',
    data: parameter,
  })
}

export function queryAllRouteChannels() {
  return axios({
    url: '/channel/queryAllRouteChannels',
    method: 'post',
  })
}

export function setMerchantRouteChannels(parameter = {}) {
  return axios({
    url: '/channel/setMerchantRouteChannels',
    method: 'post',
    data: parameter,
  })
}

export function checkMerchantChannel(parameter) {
  return axios({
    url: '/merchant/checkMerchantChannel',
    method: 'post',
    data: parameter,
  })
}
